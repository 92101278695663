import React, { useState, useEffect } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetOrganizationDetails,
  useUpdateOrganization,
} from "../apiCalls/userApiCalls";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

export default function EditOrganization() {
  const { orgId } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [instanceUrl, setInstanceUrl] = useState("");
  const [securityToken, setSecurityToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSalesforceSyncEnabled, setIsSalesforceSyncEnabled] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;

  const {
    isLoading: isOrgLoading,
    data: orgDetails,
  } = useGetOrganizationDetails(orgId, token);

  const {
    mutate: updateOrganizationMutate,
    isLoading: isUpdateOrganizationLoading,
    isError: isUpdateOrganizationError,
    error: updateOrganizationError,
  } = useUpdateOrganization();

  useEffect(() => {
    if (orgDetails && !isInitialized) {
      setName(orgDetails.data.data.name || '');
      setInstanceUrl(orgDetails.data.data.salesforceInstanceUrl || '');
      setSecurityToken(orgDetails.data.data.salesforceUserSecurityToken || '');
      setUsername(orgDetails.data.data.salesforceUsername || '');
      setPassword(orgDetails.data.data.salesforceUserPassword || '');
      setIsSalesforceSyncEnabled(orgDetails.data.data.salesforceSyncEnabled || false);
      setIsInitialized(true);
    }
  }, [orgDetails, isInitialized]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      id: orgId,
      token: token,
      name,
      salesforceInstanceUrl: instanceUrl,
      salesforceUserSecurityToken: securityToken,
      salesforceUsername: username,
      salesforceUserPassword: password,
      salesforceSyncEnabled: isSalesforceSyncEnabled,
    };

    updateOrganizationMutate(data, {
      onSuccess: () => {
        navigate(`/organizationDetails/${orgId}`);
      },
    });
  };

  const handleSalesforceSyncChange = (e) => {
    setIsSalesforceSyncEnabled(e.target.checked);
  };

  return (
    <>
      {isOrgLoading ? (
        <Loader />
      ) : (
        <div className="flex w-full items-center justify-center">
          <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
            <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
              <button
                onClick={() => window.history.back()}
                className="w-full h-full rounded-full flex items-center justify-center"
              >
                <ArrowBackIos
                  style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                />
              </button>
            </div>
            <div className="pt-10 flex flex-col items-center"></div>

            <form onSubmit={handleSubmit} className="pb-8">
              <div className="space-y-3 md:space-y-4">
                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor="name"
                  >
                    Organization Name
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black"
                      name="name"
                      placeholder="Organization Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-base md:text-lg text-gray-500" htmlFor="salesforceSyncEnabled">
                    Salesforce Sync Enabled
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="checkbox"
                      className="outline-none text-base font-medium text-black"
                      name="salesforceSyncEnabled"
                      checked={isSalesforceSyncEnabled}
                      onChange={handleSalesforceSyncChange}
                    />
                  </div>
                </div>

                {isSalesforceSyncEnabled && (
                  <>
                    <div className="space-y-2">
                      <label
                        className="text-base md:text-lg text-gray-500"
                        htmlFor="instanceUrl"
                      >
                        Salesforce Instance URL
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="text"
                          className="outline-none w-full text-base font-medium text-black"
                          name="instanceUrl"
                          placeholder="Instance URL"
                          value={instanceUrl}
                          onChange={(e) => setInstanceUrl(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <label
                        className="text-base md:text-lg text-gray-500"
                        htmlFor="username"
                      >
                        Salesforce API user's Username
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="text"
                          className="outline-none w-full text-base font-medium text-black"
                          name="username"
                          placeholder="Username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <label
                        className="text-base md:text-lg text-gray-500"
                        htmlFor="password"
                      >
                        Salesforce API User's Password
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="password"
                          className="outline-none w-full text-base font-medium text-black"
                          name="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
                      <label
                        className="text-base md:text-lg text-gray-500"
                        htmlFor="securityToken"
                      >
                        Salesforce API User's Security Token
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="text"
                          className="outline-none w-full text-base font-medium text-black"
                          name="securityToken"
                          placeholder="Security Token"
                          value={securityToken}
                          onChange={(e) => setSecurityToken(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="pt-4">
                  <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                    {isUpdateOrganizationLoading ? "Saving..." : "Save Changes"}
                  </button>
                </div>
                {isUpdateOrganizationError && (
                  <div className="text-sm font-medium text-red-600 pt-2">
                    <p>{updateOrganizationError.response.data.error}</p>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
