import Home from "./pages/Home";
import "./App.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Users from "./pages/Users";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import { useSelector } from "react-redux";
import EditUser from "./pages/EditUser";
import CreateUser from "./pages/CreateUser";
import UserDetails from "./pages/UserDetails";
import EditProfile from "./pages/EditProfile";
import { ScrollToTop } from "./hooks/ScrollToTop";
import AdminUsers from "./pages/AdminUsers";
import CreateSubUser from "./pages/CreateSubUser";
import EditSubUser from "./pages/EditSubUser";
import Organizations from "./pages/Organizations";
import OrganizationDetails from "./pages/OrganizationDetails";
import CreateOrganization from "./pages/CreateOrganization";
import EditOrganization from "./pages/EditOrganization";

const queryClient = new QueryClient();

function App() {
  const { currentUser } = useSelector((state) => state.userSlice);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        {currentUser?.user.role === "super" ? (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route path="/organizations" element={<Organizations />} />
              <Route
                path="/organizationDetails/:orgId"
                element={<OrganizationDetails />}
              />
              <Route path="/createOrganization" element={<CreateOrganization />} />
              <Route path="/editOrganization/:orgId" element={<EditOrganization />} />
              <Route path="/AdminUser/:userId" element={<AdminUsers />} />
              <Route
                path="/createSubUser/:userId"
                element={<CreateSubUser />}
              />
              <Route path="/editSubUser/:userId" element={<EditSubUser />} />
              <Route path="/UserDetails/:userId" element={<UserDetails />} />
              <Route path="/editUser/:userId" element={<EditUser />} />
              <Route path="/createUser" element={<CreateUser />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/editProfile" element={<EditProfile />} />
              <Route path="/changePassword" element={<ChangePassword />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/generatePassword" element={<ForgetPassword />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
