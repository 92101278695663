import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { EditOutlined, ArrowBackIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  useGetUserDetails,
  useUpdateUser,
  useUpdateUserImage,
} from "../apiCalls/userApiCalls";
import Loader from "../components/Loader";

export default function EditProfile() {
  const nameInputElement = useRef();
  const emailInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const userId = currentUser.user._id;
  const token = currentUser.token;

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );
  const {
    mutate: updateUserMutate,
    isLoading: isUpdateUserLoading,
    isError: isUpdateUserError,
    error: updateUserError,
  } = useUpdateUser(userId);
  const {
    mutate: updateUserImageMutate,
    isLoading: isUpdateUserImageLoading,
    isError: isUpdateUserImageError,
    error: updateUserImageError,
  } = useUpdateUserImage();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      userId: userId,
      token: token,
      name: nameInputElement.current?.value,
      email: emailInputElement.current?.value,
    };

    updateUserMutate(data);
  };

  const handleFileChange = (e) => {
    const imageData = {
      token: token,
      userId: userId,
      image: e.target.files[0],
    };
    updateUserImageMutate(imageData);
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      {isUserLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="pt-10 flex flex-col items-center">
                <form action="" className="flex items-end">
                  <div
                    className="w-28 h-28 border border-gray-200  rounded-full"
                    style={{
                      backgroundImage: `url("${userDetails.data.data.imgUrl}"), url("${fallbackImage}")`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div className="w-7 h-7 -ml-8  rounded-full bg-blue-400 text-white flex justify-center">
                    <label htmlFor="file">
                      <EditOutlined style={{ fontSize: "16" }} />
                    </label>
                  </div>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </form>
              </div>

              <form className="pt-10 space-y-3 pb-8" onSubmit={handleSubmit}>
                <div className="space-y-2">
                  <label className="text-base text-gray-500" htmlFor="">
                    Name
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      defaultValue={userDetails.data.data.name}
                      name="name"
                      ref={nameInputElement}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-base text-gray-500" htmlFor="">
                    Email Address
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="email"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      defaultValue={userDetails.data.data.email}
                      name="email"
                      ref={emailInputElement}
                    />
                  </div>
                </div>
                <div className="pt-8">
                  <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                    {isUpdateUserLoading ? "...Is Saving" : "Save"}
                  </button>
                </div>
                {isUpdateUserError && (
                  <div className="text-sm font-medium text-red-600 pt-2">
                    <p>{updateUserError.response.data.error}</p>
                  </div>
                )}
                <Link to="/changePassword">
                  <h2 className="font-medium text-end pt-3">Change Password</h2>
                </Link>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
