import React, { useRef, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  ArrowBackIos,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { useDeleteUser, useGetAdminUsers } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";
import useOutsideClick from "../hooks/useOutsideClick";
import { useParams } from "react-router-dom";

export default function AdminUsers() {
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { userId } = useParams();

  const userFilterRef = useRef();
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [sort, setSort] = useState("");
  const [limit, setLimit] = useState("20");
  const [page, setPage] = useState(1);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useOutsideClick(userFilterRef, () => {
    if (openFilter) setOpenFilter(false);
  });

  const { isLoading: isUsersLoading, data: users } = useGetAdminUsers(
    token,
    userId,
    search,
    type,
    sort,
    limit,
    page
  );
  const { mutate: deleteUserMutate, isLoading: isDeleteUserLoading } =
    useDeleteUser();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setOpenFilter(false);
    setPage(1);
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
    setOpenFilter(false);
    setPage(1);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setOpenFilter(false);
    setPage(1);
  };

  const handleClearFilters = () => {
    setSearch("");
    setType("");
    setSort("");
    setOpenFilter(false);
    setPage(1);
  };

  const handleDelete = (userId) => {
    setConfirmationPopup(true);
    setDeleteId(userId);
  };

  const handleCancelDelete = () => {
    setConfirmationPopup(false);
  };

  const handleConfirmDelete = () => {
    const data = {
      userId: deleteId,
      token: token,
    };
    setConfirmationPopup(false);
    deleteUserMutate(data);
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 min-h-screen relative">
        <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
          <button
            onClick={() => window.history.back()}
            className="w-full h-full rounded-full flex items-center justify-center"
          >
            <ArrowBackIos
              style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
            />
          </button>
        </div>
        <div className="flex pt-2 justify-between gap-3 md:gap-5 lg:gap-8 xl:gap-10">
          <div
            ref={userFilterRef}
            className={`${
              openFilter ? "" : "hidden"
            } filter-sideBar w-2/3 max-w-xs md:w-1/3 flex flex-col gap-3 rounded-lg bg-white shadow px-3 py-5 absolute z-40`}
          >
            <div className="close absolute z-20 right-2 top-2">
              <Close onClick={() => setOpenFilter(false)} />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg font-medium" htmlFor="">
                Type
              </label>
              <select
                className="outline-none px-1 py-1"
                value={type}
                onChange={handleTypeChange}
              >
                <option value="">All Roles</option>
                <option value="foreman">Foreman</option>
                <option value="worker">Worker</option>
                <option value="sales">Sales</option>
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg font-medium" htmlFor="">
                Sort
              </label>
              <select
                className="outline-none px-1 py-1"
                value={sort}
                onChange={handleSortChange}
              >
                <option value="">Default Sort</option>
                <option value="name">Ascending</option>
                <option value="-name">Descending</option>
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-lg font-medium" htmlFor="">
                Limit
              </label>
              <select
                className="outline-none px-1 py-1"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="">Default Limit</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <button
              onClick={handleClearFilters}
              className="mt-1 px-4 py-2 font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border border-gray-100 rounded-lg"
            >
              Clear
            </button>
          </div>
          <div>
            <button
              onClick={() => setOpenFilter(!openFilter)}
              className={`${
                openFilter ? "hidden" : ""
              } px-4 py-2 md:px-8 md:py-2 md:text-lg font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border hover:border-stone-50 border-gray-100 rounded-lg `}
            >
              Filter
            </button>
          </div>
          <div className="flex px-2 py-2 w-full rounded-lg btn-shadow hover:scale-105 transition-all bg-white items-center">
            <input
              type="text"
              className="px-1 w-full bg-white outline-none"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />

            <div className="text-gray-500">
              <Close className="text-gray-400" onClick={() => setSearch("")} />
            </div>
          </div>
          <Link to={`/createSubUser/${userId}`}>
            <button className="px-4 py-2 md:px-8 md:py-2 md:text-lg font-base tracking-wide hover:scale-105 transition-all bg-white btn-shadow hover:shadow-custom hover:bg-stone-50 border hover:border-stone-50 border-gray-100 rounded-lg">
              Create
            </button>
          </Link>
        </div>
        {isUsersLoading ? (
          <Loader />
        ) : (
          <>
            <div className="space-y-5 md:space-y-0 pt-8 pb-20 md:flex md:flex-wrap md:justify-between md:gap-5">
              {users.data.data.map((user) => (
                <div
                  key={user._id}
                  className="card hover:scale-105 transition-all ease-in-out bg-white card-shadow rounded-lg p-3 lg:p-4 md:w-full lg:w-5/12 xl:w-5/12"
                >
                  <div className="flex gap-5 items-center pb-4">
                    <Link to={`/userDetails/${user._id}`}>
                      <div
                        className="w-16 h-16 border border-gray-200  rounded-full"
                        style={{
                          backgroundImage: `url("${user.imgUrl}"), url("${fallbackImage}")`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                    </Link>
                    <div className="desc -space-y-1">
                      <h2 className="text-lg font-medium tracking-wide">
                        {user.name}
                      </h2>
                      <h2 className="text-lg font-light tracking-wide">
                        {user.role}
                      </h2>
                    </div>
                  </div>
                  <div className={`gap-2 justify-end text-white flex`}>
                    <Link to={`/editSubUser/${user._id}`}>
                      <div className="flex h-full items-center text-center px-4 lg:px-4 py-2 text-xs font-medium tracking-wide hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-lg">
                        Edit
                      </div>
                    </Link>
                    <button
                      onClick={() => handleDelete(user._id)}
                      className=" text-center px-4 lg:px-4 py-2 text-xs font-medium tracking-wide hover:scale-105 transition-all bg-gradient-to-b from-[#cc3636] to-[#941d1d]  hover:bg-stone-50 border border-gray-100 rounded-lg"
                    >
                      {isDeleteUserLoading ? "...is Deleting" : "Delete"}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <div
          className={`${
            confirmationPopup ? "" : "hidden"
          } fixed inset-0 flex items-center justify-center z-50`}
        >
          <div className="bg-white rounded-lg shadow-lg px-8 py-6 relative">
            <div className="flex justify-end">
              <button className=" text-gray-500 hover:text-gray-700 absolute right-2 top-2">
                <Close
                  onClick={() => setConfirmationPopup(false)}
                  style={{ fontSize: "28" }}
                  className="h-5 w-5"
                />
              </button>
            </div>

            <p className="text-lg mb-4 pt-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-between">
              <button
                onClick={handleConfirmDelete}
                className="border border-[#cc3636] hover:bg-gradient-to-b hover:text-white from-[#cc3636] to-[#941d1d]  text-gray-800 font-medium py-2 px-4 rounded mr-2"
              >
                Confirm
              </button>
              <button
                onClick={handleCancelDelete}
                className="border border-gray-400 hover:border-gray-300 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <div className="wrapper fixed bottom-0 left-0 right-0 w-full bg-white mt-20">
          <div className="pages flex gap-5 items-center justify-center  py-3">
            {users?.data.pagination.previous && (
              <div
                className="left"
                onClick={() => setPage(users?.data.pagination.previous?.page)}
              >
                <div className="w-8 h-8 hover:shadow hover:scale-110 transition-all ease-in-out border border-gray-200 rounded-full flex items-center justify-center hover:text-white hover:bg-[#59c3c2]">
                  <ChevronLeft style={{ fontSize: "24" }} />
                </div>
              </div>
            )}
            <div className="numbers flex gap-2">
              <p className="hover:scale-125 transition-all ease-in-out hover:font-bold">
                {page}
              </p>
            </div>
            {users?.data.pagination.next && (
              <div
                className="right"
                onClick={() => setPage(users?.data.pagination.next?.page)}
              >
                <div className="w-8 h-8 hover:shadow hover:scale-110 transition-all ease-in-out border border-gray-200 rounded-full flex items-center justify-center hover:text-white hover:bg-[#59c3c2]">
                  <ChevronRight style={{ fontSize: "24" }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
