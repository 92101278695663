import React, { useRef, useState } from "react";
import { VisibilityOff, Visibility, ArrowBackIos } from "@mui/icons-material";
import { useCreateUser, useGetOrganizations } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

export default function CreateUser() {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState("");

  const nameInputElement = useRef();
  const emailInputElement = useRef();
  const passwordInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const navigate = useNavigate();

  const {
    mutate: createUserMutate,
    isLoading: isCreateUserLoading,
    isError: isCreateUserError,
    error: createUserError,
  } = useCreateUser();

  const {
    isLoading: isOrganizationsLoading,
    data: organizations,
  } = useGetOrganizations(token);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      token: token,
      name: nameInputElement.current?.value,
      email: emailInputElement.current?.value,
      role: "admin",
      password: passwordInputElement.current?.value,
      organization: selectedOrganization,
    };

    createUserMutate(data, {
      onSuccess: () => {
        navigate(`/userDetails`);
      },
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <div className="pt-10 flex flex-col items-center"></div>

          {isOrganizationsLoading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit} className="pb-8">
              <div className="space-y-3 md:space-y-4">
                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor=""
                  >
                    Name
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black "
                      name="name"
                      placeholder="Fullname"
                      ref={nameInputElement}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor=""
                  >
                    Email Address
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="email"
                      className="outline-none w-full text-base font-medium text-black "
                      name="email"
                      placeholder="user@gmail.com"
                      ref={emailInputElement}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor=""
                  >
                    Password
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="outline-none w-full text-base font-medium text-black"
                      name="password"
                      placeholder="password"
                      ref={passwordInputElement}
                    />
                    <div
                      onClick={handleTogglePassword}
                      className="hover:cursor-pointer"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </div>
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-base md:text-lg text-gray-500" htmlFor="">
                    Organization
                  </label>
                  {organizations.data.data.length > 0 ? (
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <select
                        className="outline-none w-full text-base font-medium text-black"
                        value={selectedOrganization}
                        onChange={(e) => setSelectedOrganization(e.target.value)}
                      >
                        <option value="">Select Organization</option>
                        {organizations.data.data.map((org) => (
                          <option key={org._id} value={org._id}>
                            {org.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <button
                        type="button"
                        className="px-3 py-2 text-white font-medium rounded-xl bg-[#59c3c2]"
                        onClick={() => navigate("/createOrganization")}
                      >
                        Create Organization
                      </button>
                    </div>
                  )}
                </div>
                <div className="pt-4">
                  <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                    {isCreateUserLoading ? "Creating Admin..." : "Create Admin"}
                  </button>
                </div>
                {isCreateUserError && (
                  <div className="text-sm font-medium text-red-600 pt-2">
                    <p>{createUserError.response.data.error}</p>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
